<template>
  <div>
    <div>
      <div class="page-wrapper chiller-theme" style="margin-top: 71px">
        <Toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
          :time="10000" @clearToast="clearToast" />
        <main class="page-content">
          <div class="container-fluid">
            <div class="d-flex justify-content-between">
              <ul id="breadcrumb" class="breadcrumb ProximaNovaRegular mx-4">
                <!-- <li>
                  <router-link to="/demo/hp/platform/models">
                    <span>Model</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/demo/hp/platform/stat">
                    <span>Sale Statistics</span>
                  </router-link>
                </li> -->
                <li class="ProximaNovaBold" v-if="activeTab === 'Causal Insights'">
                  Causal Insights
                </li>
                <li class="ProximaNovaBold" v-if="activeTab === 'Future Planning'">
                  Future Planning
                </li>
                <li class="ProximaNovaBold" v-if="activeTab === 'ROI Summary'">
                  ROI Summary
                </li>
              </ul>
              <div v-if="activeTab === 'Causal Insights'">
                <div class="" style="height: 40%">
                  <div class="w-100 p-4 pr-5">
                    <w-white-dropdown :options="marketDropdown" :placeHolder="'Select'" :selectedOption="selectedMarket"
                      :labelText="'Market type'" class="mr-3" @input="selectMarket($event)"></w-white-dropdown>
                  </div>
                </div>
              </div>
              <div v-if="activeTab === 'Future Planning'">
                <div class="" style="height: 40%">
                  <div class="w-100 p-4 pr-5">
                    <w-white-dropdown :options="periodDropdown" :placeHolder="'Select'" :selectedOption="selectedPeriod"
                      :labelText="'Period'" class="mr-3" @input="selectPeriod($event)"></w-white-dropdown>
                  </div>
                  <p class="fs-3 font-weight-bold ml-4 d-flex justify-content-center"
                    v-if="selectedPeriod.text === 'Quaterly'">
                    April 2022 - June 2022
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid mt-5">
            <w-tab :tabName="activeTab" :tabs="tabs" @activeTab="changeTab($event)">
            </w-tab>
            <div v-if="activeTab === 'Causal Insights'">
              <div class="mt-5 d-none" :class="breakDown ? 'card' : ''">
                <b-row class="no-gutters">
                  <b-col lg="4" :class="breakDown ? '' : 'card'">
                    <div>
                      <pie-chart title="Overall" :colors="pieChartColor" :series="overallSeries" :showBreakdown="true"
                        :breakdown="breakDown" @getBreakdown="getBreakdown" />
                    </div>
                  </b-col>
                  <b-col lg="8" v-if="breakDown" class="breakdownable-card">
                    <multiple-donut-chart :series1="externalData" :series2="internalData" :chart2Colors="chart2Color"
                      :title="multipleTitle" />
                  </b-col>
                </b-row>
              </div>

              <div class="card mt-5 p-5">
                <b-container fluid>
                  <w-columnchart :chartData="marriotAttribution" />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <!-- <div class="d-flex justify-content-end">
                  <w-white-dropdown :options="dataTypeDropdown" :placeHolder="'Select'"
                    :selectedOption="selectedDataTypeDropdown" :labelText="'Data'" class="mr-3"
                    @input="selectDataTypeDropdown($event)" />
                </div> -->
                <b-container class="mt-5" fluid>
                  <w-multibreakdown-chart-data :expandData="invsroiExpandData"
                    :thirdLevelData="invsroiExpandLevelThreeData" :FourLevelData="invsroiExpandLevelFourData"
                    :dropdownList="invetmentvsroiDropdown" :secondLeveldropdownList="invetmentvsroiDropdown2"
                    :selectedOption="secondLevelInvestmentVSRoiTitle" :selectedSecondLevelOption="
                      selectedSecondLevelInvestmentROI
                    " :selectedFourLevelOption="selectedFourLevelInvestmentROI" :chartData="investRoiData"
                    @clickedChartLevelThreeExpand="
                      getLevelFourInvsVsRoiData($event)
                    " @chartClick="getLevelOneInvsVsRoiData($event)" @chartClick1="getLevelTwoInvsVsRoiData($event)"
                    @selectedOption="selectedInvestmentVSRoiDropdown($event)" @selectedSecondLevelOption="
                      selectedLevelTwoInvestmentVSRoiDropdown($event)
                    " :text="'Attribution by Campaign Type'" class="p-0"
                    :levelFourshowChartBreakdown="islevelFourshowChartBreakdown" :showChartBreakdown="isBreakdownOn"
                    :showBreakdown="isSecondLevelBreakdownOn">
                  </w-multibreakdown-chart-data>
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <w-columnchart :chartData="interactionBrandChart" />
                </b-container>
              </div>

              <div class="card mt-5 p-5">
                <b-container fluid>
                  <w-columnchart :chartData="interactionPerformanceChart" />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <w-columnchart :chartData="marriotRevenueSpends" />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <pie-chart title="Media split by Upper Funnel vs Lower Funnel" :colors="pieChartColor"
                    :series="overallSeries1" :showBreakdown="true" :breakdown="breakDown"
                    @getBreakdown="getBreakdown" />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <w-columnchart :chartData="upperFunnel" />
                </b-container>
              </div>
              <div class="card mt-5 p-5">
                <b-container fluid>
                  <w-columnchart :chartData="lowerFunnel" />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Digital :data="digitalExpandData" :stripData="digitalData" @getDataBreakdown="getDigitalBreakdown" />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Content :barData="printExpandData" :stripData="printData" :title="'Print'"
                    @getDataBreakdown="getPrintBreakdown" />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Content :barData="radioExpandData" :stripData="radioData" :title="'Radio'"
                    @getDataBreakdown="getRadioBreakdown" />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Content :stripData="sponsorshipData" :title="'Sponsorship'" :breakeDownShow="false"
                    :showBreakdownIcon="false" />
                </b-container>
              </div>
              <div class="card mt-5 p-5 d-none">
                <b-container fluid>
                  <Content :barData="contentExpandData" :stripData="contentData"
                    @getDataBreakdown="getContentBreakdown" />
                </b-container>
              </div>
              <!--<div class="card mt-5 p-5">
                <b-container fluid>
                  <Content
                    :barData="billboardExpandData"
                    :title="'Billboard'"
                    :stripData="billboardData"
                    @getDataBreakdown="getBillboardBreakdown"
                  />
                </b-container>
              </div>-->
            </div>

            <!-- <w-summary v-if="activeTab === 'ROI Summary'"></w-summary> -->
            <w-future-planning v-if="activeTab === 'Future Planning'"></w-future-planning>
          </div>
          <page-loader v-if="this.isLoading"></page-loader>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import { abbreviateNumber } from "js-abbreviation-number";

import Tab from "@/components/Solutions/Tab.vue";
import PieChart from "@/components/Chart/PieChart.vue";
import Digital from "@/components/Solutions/Digital.vue";
import Content from "@/components/Solutions/Content.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import MultipleDonutChart from "@/components/Chart/MultipleDonutChart.vue";
// import ROISummary from "./ROI-Summary.vue";
import FuturePlanning from "@/pages/DEMO/AIMO-Ecomm/FuturePlanning.vue";
import { AimoINServices } from "@/services/AimoINServices.js";
const aimoUSAService = new AimoINServices();
import PageLoader from "@/widgets/PageLoader.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import MultiBreakdownChannelWiseData from "@/components/Summary/MultiBreakdownChannelWiseData.vue";
import Toast from "@/components/Toast/Toast.vue";
// import axios from "axios";
const causaInsightsData = require('../../../data/causalInsightsEcommData.json')
// import { AimoDatapoemServices } from "@/services/AimoDatapoemServices.js";
// const aimoDatapoemService = new AimoDatapoemServices();

export default {
  name: "Insights",
  components: {
    PageLoader,
    Toast,
    // "w-summary": ROISummary,
    "w-tab": Tab,
    "w-white-dropdown": WhiteDropdown,
    "w-future-planning": FuturePlanning,
    "w-columnchart": ColumnChart,
    "w-multibreakdown-chart-data": MultiBreakdownChannelWiseData,

    PieChart,
    Digital,
    Content,
    MultipleDonutChart,
  },
  data() {
    return {
      isLoading: false,
      selectedLevelOne:"",
      selectedLevelTwo:"",
      selectedLevelThree:"",
      selectedDataTypeDropdown: { id: "Attribution (%)", text: "Attribution" },
      dataTypeDropdown: [
        { text: "ROI", id: "ROI" },
        { text: "GRE", id: "GRE" },
        { text: "Attribution", id: "Attribution (%)" },
      ],
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      periodDropdown: [
        { text: "Quaterly", id: "India" },
        { text: "Half yearly", id: "Half yearly", disable: true },
        { text: "Annually", id: "Annually", disable: true },
      ],
      selectedPeriod: "",
      islevelFourshowChartBreakdown: false,
      isBreakdownOn: true,
      isSecondLevelBreakdownOn: false,

      investRoiData: {
        data: [
          {
            name: "Investment (%)",
            data: [],
          },
          {
            name: "",
            data: [],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "% attribution",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        dataLabels: {
          enabled: this.disableDataLabel,
          annotation: this.annotation,
          formatter: function (data) {
            if (this.y > 999) {
              return (
                abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                }) + data.annotation
              );
            } else {
              return Math.round(this.y * 10) / 10 + data.annotation;
            }
          },
        },
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      secondLevelInvestmentVSRoiTitle: {},
      selectedSecondLevelInvestmentROI: {
        id: "Digital Display",
        text: "Digital Display",
      },
      selectedFourLevelInvestmentROI: {
        id: "Digital Display",
        text: "Digital Display",
      },
      invetmentvsroiDropdown: [
        {
          id: "Brand Baseline",
          text: "Brand Baseline",
        },
        {
          id: "Performance",
          text: "Performance",
        },
      ],
      invetmentvsroiDropdown2: [
        { id: "Digital Display", text: "Digital Display" },
      ],
      invetmentvsroiDropdown3: [],

      invsroiExpandLevelThreeData: {
        data: [
          {
            name: "Investment (%)",
            data: [],
          },
          {
            name: "",
            data: [],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "% attribution",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      invsroiExpandLevelFourData: {
        data: [
          {
            name: "Investment (%)",
            data: [],
          },
          {
            name: "",
            data: [],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "% attribution",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },

      invsroiExpandData: {
        data: [
          {
            name: "Investment (%)",
            data: [],
          },
          {
            name: "",
            data: [],
          },
        ],
        xAxis: {
          categories: ["2018", "2019"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "% attribution",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#b5a9ff", "#67e8ff"],
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      upperFunnel: {
        data: [
          {
            name: "Upper Funnel",
            data: [65, 20, 15],
            data1: [65, 20, 15],
          },
        ],
        xAxis: {
          min: 0,

          categories: ["Branding", "R & B", "Wedding"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "% attribution",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffd740"],
        title: {
          text: "Upper Funnel",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      marriotAttribution: {
        data: [
          {
            name: "",
            data: [40, 35, 7, 18],
          },
        ],
        xAxis: {
          categories: [
            "Brand Baseline",
            "Media Spends",
            "CRM",
            "Owned & Earned Media",
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "% attribution",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffd740"],
        title: {
          text: "Attribution (%)",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      marriotRevenueSpends: {
        data: [
          {
            name: "Media",
            data: [29, 18, 10, 8, 35],
          },
          // {
          //   name: "Spends",
          //   data: [26, 21, 11, 6, 36],
          // },
        ],
        xAxis: {
          categories: ["TV", "Print", "Radio", "DOOH", "Digital"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "% attribution",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffd740"],
        title: {
          text: "Attribution",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      interactionBrandChart: {
        data: [
          {
            name: "Campaign",
            data: [60, 16, 14, 7, 3],
          },
        ],
        xAxis: {
          categories: [
            "Performance",
            "Brand",
            "App Downloads",
            "R & B",
            "Weddings",
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "% attribution",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffd740"],
        title: {
          text: "Interactions Brand",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      interactionPerformanceChart: {
        data: [
          {
            name: "Campaign",
            data: [60, 16, 14, 7, 3],
          },
        ],
        xAxis: {
          categories: [
            "Performance",
            "Brand",
            "App Downloads",
            "R & B",
            "Weddings",
          ],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "% attribution",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#18ffff", "#ffd740"],
        title: {
          text: "Interactions Performance",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      lowerFunnel: {
        data: [
          {
            name: "Lower Funnel",
            data: [65, 35],
          },
        ],
        xAxis: {
          categories: ["Performance", "App Download"],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "% attribution",
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.4,
            borderWidth: 0,
          },
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        color: ["#ffd740", "#18ffff"],
        title: {
          text: "Lower Funnel",
          align: "left",
          margin: 60,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        subtitle: {},
        legend: {},
        tooltip: {
          headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
          pointFormat:
            '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
            '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          shadow: false,
          style: {
            padding: 0,
          },
        },
      },
      mediaData: [],
      pieChartColor: ["#ff4081", "#64ffda"],
      chart2Color: [
        "#6a1b9a",
        "#d81b60",
        "#1e88e5",
        "#a71313",
        "#5e35b1",
        "#00acc1",
        "#fb8c00",
        "#d81b60",
        "#64ffda",
      ],
      count: 0,
      breakDown: true,
      billboardData: [],
      billboardExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      contentBreakDown: false,
      activeTab: "Causal Insights",
      activeFrom: 1,
      selectedMarket: { id: "Overall", text: "Overall" },
      tabs: [
        {
          category: "Causal Insights",
        },
        {
          category: "Future Planning",
        },
        // {
        //   category: "ROI Summary",
        // },
      ],
      marketDropdown: [
        { text: "Overall", id: "Overall" },
        { text: "Brand.com", id: "Brand.com" },
        { text: "Online", id: "Online", disable: true },
        { text: "Offline", id: "Offline", disable: true },
        // { text: "TN", id: "TN", disable: true },
        // { text: "KER", id: "ker", disable: true },
        // { text: "KA", id: "KA", disable: true },
      ],
      overallSeries: [
        {
          name: "Funnel",
          colorByPoint: true,
          data: [
            {
              name: "Upper Funnel",
              y: 53,
              sliced: true,
            },
            {
              name: "Lower Funnel",
              y: 47,
              sliced: true,
            },
          ],
        },
      ],
      overallSeries1: [
        {
          name: "Funnel",
          colorByPoint: true,
          data: [
            {
              name: "Upper Funnel",
              y: 53,
              sliced: true,
            },
            {
              name: "Lower Funnel",
              y: 47,
              sliced: true,
            },
          ],
        },
      ],
      color: [
        "#f77f00",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
        "#08a0e9",
        "#1877f2",
        "#148f3a",
        "#0077b5",
        "#dd2a7b",
      ],
      tvData: [
        {
          label: "Google Ads",
          value: 31,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 22,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 17,
          backgroundColor: "#148f3a",
        },
        {
          label: "Publishers",
          value: 15,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 10,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 5,
          backgroundColor: "#08a0e9",
        },
      ],
      printData: [],
      digitalData: [
        {
          label: "Google Ads",
          value: 31,
          backgroundColor: "#f77f00",
        },
        {
          label: "Facebook",
          value: 22,
          backgroundColor: "#1877f2",
        },
        {
          label: "DV360",
          value: 17,
          backgroundColor: "#148f3a",
        },
        {
          label: "Publishers",
          value: 15,
          backgroundColor: "#0077b5",
        },
        {
          label: "Instagram",
          value: 10,
          backgroundColor: "#dd2a7b",
        },
        {
          label: "Twitter",
          value: 5,
          backgroundColor: "#08a0e9",
        },
      ],
      contentData: [
        {
          label: "Paid Media",
          value: 61,
          backgroundColor: "#85edff",
        },
        {
          label: "Owned Content",
          value: 39,
          backgroundColor: "#b5a9ff",
        },
      ],
      externalData: [
        {
          name: "Seasonality",
          y: 55,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id1",
        },
        {
          name: "Competition",
          y: 23,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "Consumer Sentiment",
          y: 12,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Dollar value",
          y: 10,
          z: 92.9,
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
      ],
      internalData: [
        {
          name: "Media",
          y: 52,
          z: 92.9,
          size: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 5 ? this.point.name : null;
            },
            color: "#ffffff",
            distance: -30,
          },
          id: "id1",
        },
        {
          name: "Product",
          y: 22,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id2",
        },
        {
          name: "Promotions",
          y: 12,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id3",
        },
        {
          name: "Distribution",
          y: 10,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id4",
        },
        {
          name: "PR",
          y: 4,
          z: 92.9,
          size: "80%",
          innerSize: "60%",
          dataLabels: {
            formatter: function () {
              return this.y > 1
                ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                : null;
            },
          },
          id: "id5",
        },
      ],
      contentExpandData: {
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
      },
      tvExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      digitalExpandData: [
        {
          name: "Video Campaigns",
          pointWidth: 20,
          data: [
            {
              x: 50,
              x2: 60,
              y: 0,
              pointWidth: 16,
              pointPadding: 0,
              legendColor: "black",
            },
          ],
          dataLabels: {
            enabled: true,
          },
        },
        {
          name: "Engagement Campaigns",
          pointWidth: 20,
          data: [
            {
              x: 1,
              x2: 20,
              y: 1,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: true,
          },
        },
        {
          name: "Lead Ads",
          pointWidth: 20,
          data: [
            {
              x: 20,
              x2: 30,
              y: 2,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: true,
          },
        },
        {
          name: "Click to website",
          pointWidth: 20,
          color: "red",
          data: [
            {
              x: 60,
              x2: 100,
              y: 3,
              pointWidth: 16,
              pointPadding: 0,
            },
          ],
          dataLabels: {
            enabled: true,
          },
        },
      ],
      printExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      radioExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      sponsorshipData: [],
      sponsorshipExpandData: {
        xAxis: {
          categories: [],
          title: {
            text: null,
          },
          labels: {
            enabled: false,
            style: {
              color: "#8394b4",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: [
          {
            lineWidth: 1,
            title: {
              text: null,
            },
          },
          {
            min: 0,
            max: 100,
            title: {
              text: "",
              align: "high",
            },
            gridLineDashStyle: "shortdash",
            labels: {
              overflow: "justify",
              style: {
                color: "#222a37",
                fontSize: 14,
              },
              formatter: function () {
                var label = this.axis.defaultLabelFormatter.call(this);
                return label + "%";
              },
            },
            opposite: true,
            tickInterval: 25,
          },
        ],
        tooltip: {
          valueSuffix: "",
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "bottom",
          itemStyle: {
            color: "#8394b4",
          },
        },
        credits: {
          enabled: false,
        },
        colors: [
          "#ffd740",
          "#18ffff",
          "#69f0ae",
          "#ff9100",
          "#fe5151",
          "#5e35b1",
          "#d81b60",
          "#fb8c00",
        ],
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
          showInLegend: true,
          series: {
            borderRadiusTopLeft: 25,
            borderRadiusTopRight: 25,
          },
        },
        series: [],
      },
      radioData: [],
      multipleTitle: ["External", "Internal"],
    };
  },

  created() {
    this.getInvsVsRoiData();
    this.callApis();
  },
  methods: {
    selectDataTypeDropdown(e) {
      this.isBreakdownOn = false;
      this.islevelFourshowChartBreakdown = false;
      this.isSecondLevelBreakdownOn = false;
      this.selectedDataTypeDropdown = e;
      this.getInvsVsRoiData(this.selectedDataTypeDropdown);
    },
    changeAttributionPercentageChart(){
      if(causaInsightsData[`${this.selectedMarket?.text}`]){
        this.marriotAttribution.data = []
        this.marriotAttribution.xAxis.categories = []
        let chartData = causaInsightsData[`${this.selectedMarket?.text}`].attributionPercentage
        this.marriotAttribution.data = chartData.data
        this.marriotAttribution.xAxis.categories = chartData.categories
      }
    },
    changeBrandInteractionsChart(){
      if(causaInsightsData[`${this.selectedMarket?.text}`]){
        this.interactionBrandChart.data = []
        this.interactionBrandChart.xAxis.categories =[]
        let chartData = causaInsightsData[`${this.selectedMarket?.text}`].interactionsBrand
        this.interactionBrandChart.data = chartData.data
        this.interactionBrandChart.xAxis.categories = chartData.categories
      }
    },
    changePerformanceInteractionsChart(){
      if(causaInsightsData[`${this.selectedMarket?.text}`]){
        this.interactionPerformanceChart.data = []
        this.interactionPerformanceChart.xAxis.categories = []
        let chartData = causaInsightsData[`${this.selectedMarket?.text}`].interactionsPerformance
        this.interactionPerformanceChart.data = chartData.data
        this.interactionPerformanceChart.xAxis.categories = chartData.categories
      }
    },
    changeUpperLowerFunnel(){
      if(causaInsightsData[`${this.selectedMarket?.text}`]){
        this.overallSeries1[0].data = []
        let chartData = causaInsightsData[`${this.selectedMarket?.text}`].uppperFunnelLowerFunnel
        this.overallSeries1[0].data = chartData.data
        console.log(this.overallSeries1, chartData.data, "overallSeries!")
      }
    },
    changeUpperFunnel(){
      if(causaInsightsData[`${this.selectedMarket?.text}`]){
        this.upperFunnel.data = []
        this.upperFunnel.xAxis.categories = []
        let chartData = causaInsightsData[`${this.selectedMarket?.text}`].overallUpperFunnel
        this.upperFunnel.data = chartData.data
        this.upperFunnel.xAxis.categories = chartData.categories
      }
    },
    changeLowerFunnel(){
      if(causaInsightsData[`${this.selectedMarket?.text}`]){
        this.lowerFunnel.data = []
        this.lowerFunnel.xAxis.categories = []
        let chartData = causaInsightsData[`${this.selectedMarket?.text}`].overallLowerFunnel
        this.lowerFunnel.data = chartData.data
        this.lowerFunnel.xAxis.categories = chartData.categories
      }
    },
    changeAttribution(){
      if(causaInsightsData[`${this.selectedMarket?.text}`]){
        this.marriotRevenueSpends.data = []
        this.marriotRevenueSpends.xAxis.categories = []
        let chartData = causaInsightsData[`${this.selectedMarket?.text}`].attribution
        this.marriotRevenueSpends.data = chartData.data
        this.marriotRevenueSpends.xAxis.categories = chartData.categories
      }
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    selectPeriod(e) {
      this.selectedPeriod = e;
    },
    getInvsVsRoiData() {
      if(causaInsightsData[`${this.selectedMarket?.text}`]){
        let res = causaInsightsData[`${this.selectedMarket?.text}`].attributionByCampaignType
        this.invetmentvsroiDropdown = [];
        this.investRoiData.data[0].data = [];
        this.investRoiData.data[0].name = "Investment";
        this.investRoiData.data[1].name = "Revenue";

        this.investRoiData.data[1].data = [];
        this.investRoiData.xAxis.categories = [];
        for (var i = 0; i < res.response.levelOne.length; i++) {
          this.investRoiData.data[0].data.push(res.response.levelOne[i].investment);
          this.investRoiData.data[1].data.push(res.response.levelOne[i].revenue);
          this.investRoiData.xAxis.categories.push(
            res.response.levelOne[i].name
          );
          this.invetmentvsroiDropdown.push({
            id: res.response.levelOne[i].name,
            text: res.response.levelOne[i].name,
          });
        }
      }
    },

    getLevelOneInvsVsRoiData(data) {
      this.secondLevelInvestmentVSRoiTitle = data;
      this.isBreakdownOn = false;
      this.islevelFourshowChartBreakdown = false;

      let res = causaInsightsData[`${this.selectedMarket?.text}`].attributionByCampaignType

      const selectedChannel = res.response.levelTwo.filter((item) =>
        item.name === data.text
      );
      // aimoDatapoemService
      //   .getChartData(
      //     this.selectedBrand.text,
      //     this.selectedMedia.id,
      //     "investmentvsrevenue",
      //     this.clientId
      //   )
      //   .then((res) => {

      // isBreakdownOn

      selectedChannel[0].value.length === 0
        ? ((this.isSecondLevelBreakdownOn = false),
          (this.toastData = {
            show: true,
            type: "info",
            message: "Data is not available for this selection",
          }))
        : ((this.isSecondLevelBreakdownOn = true), this.clearToast());

      this.invsroiExpandData.data[0].data = [];
      this.invsroiExpandData.data[0].name = "Investment";
      this.invsroiExpandData.data[1].name = "Revenue";

      this.invsroiExpandData.data[1].data = [];
      this.invsroiExpandData.xAxis.categories = [];

      for (let i = 0; i < selectedChannel[0].value.length; i++) {
        this.invsroiExpandData.data[0].data.push(
          selectedChannel[0].value[i].investment
        );
        this.invsroiExpandData.data[1].data.push(
          selectedChannel[0].value[i].revenue
        );

        this.invsroiExpandData.xAxis.categories.push(
          selectedChannel[0].value[i].name
        );
      }
    },

    getLevelFourInvsVsRoiData(data) {
      console.log("sljdnvsj")
      let res = causaInsightsData[`${this.selectedMarket?.text}`].attributionByCampaignType
      const selectedChannel = res.response.levelFour.filter((item) =>
          item.name === data.text
        );

        if (selectedChannel[0].value.length !== 0) {
          this.isBreakdownOn = true;
          this.clearToast();

          this.invsroiExpandLevelFourData.data[0].data = [];
          this.invsroiExpandLevelFourData.data[0].name = "Investment";
          this.invsroiExpandLevelFourData.data[1].name = "Revenue";
          this.invsroiExpandLevelFourData.data[1].data = [];
          this.invsroiExpandLevelFourData.xAxis.categories = [];

          for (let i = 0; i < selectedChannel[0].value.length; i++) {
            this.invsroiExpandLevelFourData.data[0].data.push(
              selectedChannel[0].value[i].investment
            );
            this.invsroiExpandLevelFourData.data[1].data.push(
              selectedChannel[0].value[i].revenue
            );

            this.invsroiExpandLevelFourData.xAxis.categories.push(
              selectedChannel[0].value[i].name
            );
          }
          this.islevelFourshowChartBreakdown = true;
        } else {
          this.islevelFourshowChartBreakdown = false;
          this.toastData = {
            show: true,
            type: "info",
            message: "Data is not available for this selection",
          };
        }
    },

    getLevelTwoInvsVsRoiData(data) {
      console.log(data);
      this.islevelFourshowChartBreakdown = false;
      let res = causaInsightsData[`${this.selectedMarket?.text}`].attributionByCampaignType
      const selectedChannel = res.response.levelThree.filter((item) =>
          item.name === data.text
        );

        if (selectedChannel[0].value.length !== 0) {
          this.isBreakdownOn = true;
          this.clearToast();

          this.invsroiExpandLevelThreeData.data[0].data = [];
          this.invsroiExpandLevelThreeData.data[0].name = "Investment";
          this.invsroiExpandLevelThreeData.data[1].name = "Revenue";
          this.invsroiExpandLevelThreeData.data[1].data = [];
          this.invsroiExpandLevelThreeData.xAxis.categories = [];

          for (let i = 0; i < selectedChannel[0].value.length; i++) {
            this.invsroiExpandLevelThreeData.data[0].data.push(
              selectedChannel[0].value[i].investment
            );
            this.invsroiExpandLevelThreeData.data[1].data.push(
              selectedChannel[0].value[i].revenue
            );

            this.invsroiExpandLevelThreeData.xAxis.categories.push(
              selectedChannel[0].value[i].name
            );
          }
        } else {
          this.isBreakdownOn = false;
          this.toastData = {
            show: true,
            type: "info",
            message: "Data is not available for this selection",
          };
        }
      // });
    },

    selectedInvestmentVSRoiDropdown(data) {
      
      let res = causaInsightsData[`${this.selectedMarket?.text}`].attributionByCampaignType
      // aimoDatapoemService
      //   .getChartData(
      //     this.selectedBrand.text,
      //     this.selectedMedia.id,
      //     "investmentvsrevenue",
      //     this.clientId
      //   )
      //   .then((res) => {
      const selectedChannel = res.response.levelTwo.filter((item) =>
        item.name === data.text
      );

      this.invsroiExpandData.data[0].data = [];
      this.invsroiExpandData.data[1].data = [];
      this.invsroiExpandData.xAxis.categories = [];

      for (let i = 0; i < selectedChannel[0].value.length; i++) {
        this.invsroiExpandData.data[0].data.push(
          selectedChannel[0].value[i].investment
        );
        this.invsroiExpandData.data[1].data.push(
          selectedChannel[0].value[i].revenue
        );

        this.invsroiExpandData.xAxis.categories.push(
          selectedChannel[0].value[i].name
        );
      }
      // .catch((err) => {
      //   alert(err);
      // });
      // });
    },

    selectedLevelTwoInvestmentVSRoiDropdown(data) {
      // aimoDatapoemService
      //   .getChartData(
      //     this.selectedBrand.text,
      //     this.selectedMedia.id,
      //     "investmentvsrevenue",
      //     this.clientId
      //   )
      //   .then((res) => {
      let res = causaInsightsData[`${this.selectedMarket?.text}`].attributionByCampaignType

      this.invetmentvsroiDropdown2 = [];

      for (let i = 0; i < res.response.levelThree.length; i++) {
        this.invetmentvsroiDropdown2.push({
          text: res.response.levelThree[i].name,
          id: i,
        });
      }
      this.selectedSecondLevelInvestmentROI = data;
      this.getLevelTwoInvsVsRoiData(data);
      // });
    },
    callApis() {
      this.count = 0;
      console.log(causaInsightsData, ":causaInsightsData")
      this.isBreakdownOn = false;
      this.islevelFourshowChartBreakdown = false;
      this.isSecondLevelBreakdownOn = false
      this.changeAttributionPercentageChart()
      this.changeBrandInteractionsChart()
      this.changePerformanceInteractionsChart()
      this.changeUpperLowerFunnel()
      this.changeUpperFunnel()
      this.changeLowerFunnel()
      this.changeAttribution()
      // this.selectedMarket = JSON.parse(sessionStorage.getItem("kpiMarketIndia"));
      this.getOverallChartData();
      this.getExternalData();
      this.getInternalData();
      this.getMediaData();
      this.getFirstLevelData("tvData", "tv");
      this.getFirstLevelData("digitalData", "digital");
      this.getFirstLevelData("printData", "print");
      this.getFirstLevelData("radioData", "radio");
      this.getFirstLevelData("sponsorshipData", "sponsorship");
      this.getFirstLevelData("contentData", "content");
      this.getFirstLevelData("billboardData", "billboardlevelone");
    },
    getFirstLevelData(dataVariable, param) {
      var color = "";
      if (param === "content") {
        color = "rgb(251,140,0)";
      } else if (param === "tv") {
        color = "rgb(216,27,96)";
      } else if (param === "print") {
        color = "rgb(94,53,177)";
      } else if (param === "radio") {
        color = "rgb(167,19,19)";
      } else if (param === "sponsorship") {
        color = "rgb(30,136,229)";
      } else if (param === "billboardlevelone") {
        color = "rgb(0,172,193)";
      } else if (param === "digital") {
        color = "rgb(106,27,154)";
      } else {
        color = "rgb(247,127,0)";
      }
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          param,
          this.selectedMarket.id
        )
        .then((res) => {
          // var opacity = 1;
          this[dataVariable] = [];
          for (var i = 0; i < res.length; i++) {
            var eachOpacity;
            if (i === 0) {
              eachOpacity = 1;
            } else {
              if (i > 10) {
                eachOpacity = 0.5;
              } else {
                eachOpacity = (100 - i * 10) / 100;
              }
            }
            this[dataVariable].push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: this.getColor(color, eachOpacity),
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getDigitalBreakdown(name) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          name.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.digitalExpandData = [];
          var count = 0;
          for (var i = 0; i < res.length; i++) {
            this.digitalExpandData.push({
              name: res[i].name,
              pointWidth: 20,
              data: [
                {
                  x: count,
                  x2: count + res[i].value,
                  y: i + 1,
                  pointWidth: 16,
                  pointPadding: 0,
                },
              ],
              dataLabels: {
                enabled: true,
              },
            });
            count += res[i].value;
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getPrintBreakdown(data) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.printExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.printExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getRadioBreakdown(data) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.radioExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.radioExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
        });
    },
    getSponsorshipBreakdown(data) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.sponsorshipExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.sponsorshipExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getContentBreakdown(data) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          data.label.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.contentExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.contentExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    // getBillboardBreakdown(data) {
    //   aimoUSAService
    //     .getChartData(
    //       "sales causality",
    //       "/b2c/in/",
    //       data.label.toLowerCase(),
    //       this.selectedMarket.id
    //     )
    //     .then((res) => {
    //       this.billboardExpandData.series = [];
    //       for (var i = 0; i < res.length; i++) {
    //         this.billboardExpandData.series.push({
    //           name: res[i].name,
    //           data: [res[i].value],
    //           pointWidth: 16,
    //           yAxis: 1,
    //         });
    //       }
    //       this.count++;
    //     })
    //     .catch(() => {
    //       this.count++;
    //     });
    // },
    getTvExpandData(name) {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          name.toLowerCase(),
          this.selectedMarket.id
        )
        .then((res) => {
          this.tvExpandData.series = [];
          for (var i = 0; i < res.length; i++) {
            this.tvExpandData.series.push({
              name: res[i].name,
              data: [res[i].value],
              pointWidth: 16,
              yAxis: 1,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    gettvBreakdown(data) {
      this.getTvExpandData(data.label);
    },
    getExternalData() {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          "external factors",
          this.selectedMarket.id
        )
        .then((res) => {
          this.externalData = [];
          for (var i = 0; i < res.length; i++) {
            var name = "";
            if (res[i].name === "GDP") {
              name = "Consumer Sentiment";
            } else {
              name = res[i].name;
            }
            this.externalData.push({
              name: name,
              y: res[i].value,
              z: 92.9,
              dataLabels: {
                formatter: function () {
                  return this.y > 1
                    ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                    : null;
                },
              },
              id: "id" + i,
            });
          }
        });
    },
    getInternalData() {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          "internal factors",
          this.selectedMarket.id
        )
        .then((res) => {
          this.internalData = [];
          for (var i = 0; i < res.length; i++) {
            this.internalData.push({
              name: res[i].name,
              y: res[i].value,
              z: 92.9,
              dataLabels: {
                formatter: function () {
                  return this.y > 1
                    ? "<b>" + this.point.name + ":</b> " + this.y + "%"
                    : null;
                },
              },
              id: "id" + i,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getMediaData() {
      var colors = [
        "rgb(216,27,96)",
        "rgb(106,27,154)",
        "rgb(94,53,177)",
        "rgb(167,19,19)",
        "rgb(30,136,229)",
        "rgb(0,172,193)",
        "rgb(247,127,0)",
        "rgb(247,127,0)",
        "rgb(247,127,0)",
      ];
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          "media",
          this.selectedMarket.id
        )
        .then((res) => {
          this.mediaData = [];
          for (var i = 0; i < res.length; i++) {
            this.mediaData.push({
              label: res[i].name,
              value: res[i].value,
              backgroundColor: colors[i],
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    getOverallChartData() {
      aimoUSAService
        .getChartData(
          "sales causality",
          "/b2c/in/",
          "overall",
          this.selectedMarket.id
        )
        .then((res) => {
          this.overallSeries[0].data = [];
          for (var i = 0; i < res.length; i++) {
            this.overallSeries[0].data.push({
              name: res[i].name,
              y: res[i].value,
              sliced: true,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
        });
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    getBreakdown(newVal) {
      this.breakDown = newVal;
    },
    selectMarket(e) {
      var defaultOption = JSON.parse(sessionStorage.getItem("kpiMarketIndia"));
      if (e === "Select") {
        if (e === this.selectedMarket) {
          return;
        }
        this.selectedMarket = defaultOption;
        return;
      }
      sessionStorage.setItem("kpiMarketIndia", JSON.stringify({ id: "", text: "India" }));
      this.selectedMarket = e
      this.callApis();
    },
  },
  computed: {
    getColor() {
      return (color, opacity) => {
        var x = color.split(")");
        return x[0] + "," + opacity + ")";
      };
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
}

.breakdownable-card {
  border-left: 2px solid #d4dae4;
  position: relative;
}

.breakdownable-card::before {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
  z-index: 9999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.breakdownable-card::after {
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  background: #d4dae4;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
  z-index: 999;
  clip-path: polygon(50% 0, 0 50%, 50% 100%);
}

.item {
  margin-top: 20px;
}

.title {
  font-family: ProximaNovaRegular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
  margin-bottom: 5px;
}

::v-deep .progress-bar {
  text-align: end;
  padding-right: 6px;
}
</style>
